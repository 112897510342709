.modal-div{
    padding: 25px 20px;
    position: relative;
}
.modal-div > p{
    font-weight: bolder;
    color: #0d1d36;
}
.list-style-modal >  li{
    font-size: 14px;
    color: #0d1d36;
    margin-bottom: 15px;
}
.close-button{
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 1px 5px;
    border: 2px solid #0d1d36;
    border-radius: 50%;
    background-color: #0d1d36;
    color: white;
}
.close-button:hover{
    background-color: red;
    color: white;
    border: 2px solid red;
}
.okay-button{
    
    padding: 5px;
    border: 1px solid #0d1d36;
    background-color: #0d1d36;
    color: white;
    width: 20%;
    margin-left: 40%;
    margin-right: 40%;
    border-radius: 5px;
}
.okay-button:hover{
    background-color: #253755;
    border: 1px solid #253755;
}
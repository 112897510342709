.login-style {
  margin-top: 10%;
  margin-left: 5%;
  margin-right: 5%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.login-page-style {
  background: rgb(238, 174, 202);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(214, 235, 237, 1) 100%);
}

.login-btn {
  background-color: rgb(13, 118, 238);
  color: white;
  border: 1px solid white;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.login-btn:hover {
  background-color: red;
  color: white;
  border: 1px solid white;
  cursor: pointer;
}

.login-style h3 {
  color: #0d1d36;
  font-weight: bolder;
}

.link-style {
  color: rgb(13, 118, 238);
  border: 0 !important;

}

.link-style:hover {
  color: rgb(245, 11, 23);
  border: 0 !important;
}
.login-half{
  margin-top: 13%;
  padding-left: 5%;
  padding-right: 5%;
}
@media screen and (max-width: 576px) {
  .login-style {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
  }

}
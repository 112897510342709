/* main page background */
.main-page {
  /* background: rgb(238, 174, 202);
  background: radial-gradient(circle, rgb(223, 129, 169) 0%, rgba(148, 187, 233, 1) 100%); */
  background: rgb(238, 174, 202);
 /* background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 62%); */
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(214,235,237,1) 100%);
}

/* .main-page-style {
  /* background-image: url('../../images/bg.svg'); */

  

.half-input {
  margin-top: 10%;
  padding: 20px;
}

.half-input-div {
  margin-top: 2vh;
  position: relative;
  margin-bottom: 2vh;
}

.input-style {
  width: 100%;
  padding: 10px;
  font-weight: 22px;
  border: 1px solid black;
  background-color: rgba(240, 248, 255, 0.418);
  border-radius: 5px;
}

.input-style:focus {
  outline: none;
  font-weight: bold;
}

.generate-code-btn {
  background-color: #e00;
  border-color: #e00; 	
  color: white;
  width: 100%;
  border: 1px solid white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: bold;
  
}


.code-input {
  border: 2px solid black !;
  border-radius: 0.4rem;
}

.social-link {
  list-style: none;
  display: flex;
  font-size: 1.5rem;
}

.copy-btn-div {
  position: absolute;
  top: 5px;
  right: 30px;
}

.btn-dark {
  padding: 3px 5px;
}

.Code {
  position: relative;
  overflow-y: scroll;
  height: 85vh;
  /* border: 3px solid rgb(94, 94, 94); */
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.Code::-webkit-scrollbar {
  width: .6em;
}

.Code::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.Code::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

textarea {
  border: 0 !important;
  outline: 0 !important;
}
.modal-para{
  color: #0d1d36;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: bold;

}
.modal-para:hover{
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  
}
.generate-code-para{
  background-color: #e00;
  border-color: #e00; 	
  color: white;
  width: 100%;
  border: 1px solid white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: bold;
  cursor: pointer;
}
.error-style{
  color: red;
  font-size: 14px;
}
.footer-style{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    background-color: #0d1d36;
    color: white;
}
.footer-para{
    font-size: 14px;
}
.footer-link{
    text-decoration: none;
}
.email-icon{
    font-size: 16px;
}
// Varaibles
$item-count: 7;
$item: 1 / $item-count;

.box {
  height: 40px;
  margin: auto;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  &::before {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    content: '';
    height: 10px;
    position: absolute;
    background: linear-gradient(180deg, rgba(255,255,255,1), rgba(255,255,255,0));
  }
  &::after {
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    content: '';
    height: 10px;
    position: absolute;
    background: linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,1));
  }
}

.box > ul {
  margin: 0;
  padding: 0;
  animation: scrollUp 2s .2s infinite forwards;
  li {
    opacity: 1;
    height: 30px;
    padding: 10px;
    list-style: none; 
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes scrollUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY((-100% / $item-count) * ($item-count - 1));
  }
}
.register-page {
  padding-top: 50px;
  padding-bottom: 50px;
  background: rgb(238, 174, 202);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(214, 235, 237, 1) 100%);
}

.register-style {
  width: 33%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white;
  border-radius: 15px;
}

.register-style h3 {
  color: rgb(8, 8, 10);
  margin-bottom: 25px;
}

@media screen and (max-width: 576px) {
  .register-style {
    width: 100%;
  }
}
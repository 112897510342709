

h2 {
    vertical-align: center;
    text-align: center;
}

.top-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #0d1d36;
    background: #0d1d36;
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: black;
    height: 12vh;
    padding: 1em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 16px;
    font-weight: bold;
    z-index: 1000;
}

.menu {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    z-index: 1000;
}

.menu>li {
    margin: 0 1rem;
}

.menu-button-container {
    display: none !important;
    height: 100%;
    width: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

#menu-toggle {
    display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
    display: block;
    background-color: #fff;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
}

.menu-button::before {
    content: '';
    margin-top: -8px;
}

.menu-button::after {
    content: '';
    margin-top: 8px;
}

#menu-toggle:checked+.menu-button-container .menu-button::before {
    margin-top: 0px;
    transform: rotate(405deg);
}

#menu-toggle:checked+.menu-button-container .menu-button {
    background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked+.menu-button-container .menu-button::after {
    margin-top: 0px;
    transform: rotate(-405deg);
}
.get-started-btn{
    background-color: rgb(255, 0, 0);
    padding: 8px 20px;
    text-transform: uppercase;
    color: white;
    border: 1px solid rgb(255, 0, 0);
    border-radius: 5px;
    font-weight: bold;
    
}
.get-started-btn:hover{
    background-color: rgb(255, 0, 0);
    border: 1px solid rgb(255, 0, 0);
}
.list-style{
    display: flex;
    justify-content: center;
    align-items: center;
}
.list-style > a{
    color: black;
    text-decoration: none;
}
.list-style > a:hover{
    color: rgba(68, 68, 68, 0.815);
    text-decoration: none;
}
.btn-size{
    font-size: 20px;
}
@media (max-width: 700px) {
    .menu-button-container {
        display: flex;
        justify-content: center !important;
        margin-top: .7rem;
        display: block !important;
    }
    
    .get-started-btn{
        background-color: rgb(13, 118, 238);
        padding: 3px 15px;
        text-transform: uppercase;
        color: white;
        border: 1px solid rgb(13, 118, 238);
        border-radius: 2px;
        font-weight: bold;
        font-size: 14px;
        
    }
    .menu {
        position: absolute;
        top: 0;
        margin-top: 50px;
        left: 0;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    #menu-toggle~.menu li {
        height: 0;
        margin: 0;
        padding: 0;
        border: 0;
        transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
        display: none;
    }

    #menu-toggle:checked~.menu li {
        border-bottom: 1px solid #333;
        min-height: 2.7em;
        padding: 0.5em;
        transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
        display: block;
    }

    .menu>li {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0.5em 0;
        width: 100%;
        color: black;
        background-color: white;
    }

    .menu>li:last-child {
        border-bottom: 1px solid #444;
        height: 3rem;
    }
}
